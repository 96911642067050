import React from 'react';
import { useRouter } from 'next/router';
import { useModal } from 'mui-modal-provider';
import CloseIcon from '@mui/icons-material/Close';
import { trackClickUpgradeEvent } from 'lib/hooks/useEventTracking';
import { useApi } from 'lib/contexts/ApplicationState';
import { Dialog, IconButton } from '@mui/material';

interface Props {
  text?: string;
  onCancel: () => void;
}

export const UpgradeModal = ({
  onCancel, text,
}: Props) => {
  const router = useRouter();

  const goToPlans = () => {
    router.push({ pathname: '/plans', query: { returnTo: router.pathname } });
  };

  return (
    <Dialog open>
      <div className="p-6">
        <h1 className="text-xl font-semibold mb-4">
          That&apos;s an Advanced Feature!
        </h1>
        <div className="absolute right-1 top-1">
          <IconButton
            aria-label="close"
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="mb-4 text-lg">
          {text}
        </div>
        <div className="text-center">
          <button type="button" className="btn-primary text-xl" onClick={goToPlans}>
            Upgrade today
          </button>
        </div>
      </div>
    </Dialog>
  );
};

interface PaidTeamSubscriptionModalProps {
  text?: string;
  onCancel: () => void;
  teamId?: number;
}

const PaidTeamSubscriptionModal = ({
  onCancel, text, teamId,
}: PaidTeamSubscriptionModalProps) => {
  const router = useRouter();
  const flexEmoji = String.fromCodePoint(129309);

  const goToTeams = () => {
    router.push({ pathname: `/teams/${teamId}/billing` });
  };

  return (
    <Dialog open>
      <div className="p-4">
        <h1 className="text-xl font-semibold mb-4">
          Teams feature
        </h1>
        <div className="absolute right-2 top-2">
          <IconButton
            aria-label="close"
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="mb-4">
          {text}
        </div>
        <div className="text-center">
          <div className="mb-4">
            {flexEmoji}
            &nbsp;Let&apos;s work together&nbsp;
            {flexEmoji}
          </div>
          <button type="button" className="btn-primary text-lg" onClick={goToTeams}>
            Start your subscription
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export const usePaidSubscriptionModal = () => {
  const { showModal } = useModal();
  const API = useApi();

  const showPaidSubscriptionModal = async (text?: string, teamId?: number) => {
    await trackClickUpgradeEvent(API, { text, upgradeType: 'Teams' });

    const modal = showModal(PaidTeamSubscriptionModal, {
      text: text || 'Start your Teams subscription to access this feature',
      onCancel: () => {
        modal.destroy();
      },
      teamId,
    });
  };

  return { showPaidSubscriptionModal };
};

/** Generic upgrade modal for Pro/Team features */
export const useUpgradeModal = () => {
  const { showModal } = useModal();
  const API = useApi();

  const showUpgradeModal = async (text?: string) => {
    await trackClickUpgradeEvent(API, { text, upgradeType: 'Pro' });

    const modal = showModal(UpgradeModal, {
      text: text || 'Upgrade your subscription to use this feature!',
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  return { showUpgradeModal };
};
