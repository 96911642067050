import React, { ChangeEvent, useState } from 'react';
import { useModal } from 'mui-modal-provider';
import { trackClickUpgradeEvent } from 'lib/hooks/useEventTracking';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { Checkbox, Dialog, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Team, TeamMember } from 'lib/types/teams';
import { FormInput } from 'elements/FormInput';
import { userIsAdmin } from 'lib/helpers';
import { useRouter } from 'next/router';

export const useCreateNewTeamModal = () => {
  const { showModal } = useModal();
  const API = useApi();

  const showCreateNewTeamModal = async (
    text?: string,
    onCreate?: (id: number) => void,
    onCancel?: () => void,
  ) => {
    await trackClickUpgradeEvent(API, { text, upgradeType: 'Teams' });
    const invitations = await API.getUserTeamInvites();

    const modal = showModal(CreateNewTeamModal, {
      text,
      invitations,
      onCancel: () => {
        modal.destroy();
        onCancel?.();
      },
      onCreate: (id: number) => {
        modal.destroy();
        onCreate?.(id);
      },
    });
  };

  return { showCreateNewTeamModal };
};

interface NewTeamProps {
  onCreate: (id: number) => void;
  onCancel: () => void;
  text?: string;
  invitations?: any[];
}

export const CreateNewTeamModal = ({
  onCreate, invitations, onCancel, text,
}: NewTeamProps) => {
  const API = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const [isCreating, setIsCreating] = useState(false);

  const newTeam: Team = {
    name: '',
    accounts: [],
    members: [],
    status: 'inactive',
  };
  const [_team, setTeam] = useState<Team>(newTeam);
  const [adminIsManager, setAdminIsManager] = useState(false);

  const saveTeam = async () => {
    try {
      if (adminIsManager && currentUser) {
        _team.members.push({
          id: currentUser?.id,
          role: 'manager',
          status: 'active',
        } as TeamMember);
      }
      setIsCreating(true);
      const team = await API.addTeam(_team);
      onCreate(team.id as number);
    } catch (error) {
      enqueueSnackbar(`Error creating team: ${error}`, {
        variant: 'error',
      });
    } finally {
      setIsCreating(false);
    }
  };

  const changeName = (event: ChangeEvent<HTMLInputElement>) => {
    setTeam({
      ..._team,
      name: event.target.value,
    });
  };

  const handleAcceptInvite = async (code: string) => {
    try {
      router.push(`/teams/accept/${encodeURIComponent(code)}`);
    } catch (error) {
      enqueueSnackbar(`Error accepting team invite: ${error}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      open
      onClose={onCancel}
      keepMounted
      maxWidth="md"
      fullWidth
    >
      <div className="p-4">
        <h1 className="text-lg font-semibold text-orange">
          Create a new team
        </h1>
        <div className="absolute right-2 top-2 text-orange">
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </div>
        <div>
          {text || 'To access that feature create a team:'}
          {invitations?.length ? (
            <ul>
              You have team invitations waiting for you!
              {invitations.map((i) => (
                <li className="ml-4" key={i.id}>
                  Team
                  {' '}
                  <strong>
                    {i.name}
                  </strong>
                  <button
                    type="button"
                    className="btn-primary-xs ml-2"
                    onClick={() => {
                      handleAcceptInvite(i.code);
                    }}
                  >
                    Accept
                  </button>
                  {/* TODO */}
                  {/* <button
                    type="button"
                    className="btn-primary-outlined-xs ml-2"
                    onClick={() => {
                      // API.declineInvitation(i.id);
                      onCancel();
                    }}
                  >
                    Decline
                  </button> */}
                </li>
              ))}
            </ul>
          ) : null}
          <div className="my-4">
            <FormInput
              type="text"
              id="name"
              name="name"
              placeholder="Team name"
              onChange={changeName}
              value={_team.name}
            />
          </div>
          {userIsAdmin(currentUser) && (
            <div>
              <Checkbox
                id="adminIsManager"
                name="adminIsManager"
                checked={adminIsManager}
                onChange={(e) => setAdminIsManager(e.target.checked)}
              />
              Make me the manager (admin only)
            </div>
          )}
          <div className="mb-4">
            If you are trying to join an existing team, contact the team account manager
            for an invitation.
          </div>
          <div>
            The team will only be visible to you and any team members you invite.
            After your Teams subscription starts, you will be able to add accounts and members,
            and access the other Teams features!
          </div>
        </div>
        <div className="flex mt-5">
          <div className="w-full text-right">
            <button
              type="button"
              className="btn-primary-outlined mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="btn-primary"
              onClick={saveTeam}
              type="button"
              disabled={!_team.name || isCreating}
            >
              {isCreating ? 'Creating...' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
