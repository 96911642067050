import {
  SyntheticEvent, useEffect, useMemo, useRef, useState,
} from 'react';

import {
  Avatar, Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteChangeReason, AutocompleteChangeDetails,
  TextField,
} from '@mui/material';
import {
  isEmpty, throttle, isArray, castArray,
} from 'lodash';
import { SearchRounded } from '@mui/icons-material';
import { useApi } from 'lib/contexts/ApplicationState';
import {
  User, SearchableUser, NetworkUser,
} from 'lib/types/users';
import { getAvatarOrPlaceholder } from 'lib/utils/imageUtils';
import { CreditType } from 'lib/types/credits';

interface Props {
  value?: SearchableUser | SearchableUser[] | string;
  onChange: (event: SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<User | SearchableUser>,
  ) => void;
  label: string;
  multiple?: boolean;
  isCreatableSearch?: boolean;
  postId?: number;
  creditType?: CreditType;
  size?: 'medium' | 'small';
  showSearchIcon?: boolean;
  emptyText?: string;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
}

// TODO: We could probably use two versions of UserSearch: one that's purely search,
// and one that is related to credits. The former could be a lot simpler.
export const UserSearch = ({
  value, onChange, label, multiple, isCreatableSearch = false, postId, creditType, size = 'medium', showSearchIcon, emptyText, variant,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<(string | User | SearchableUser)[]>([]);
  const isFetching = useRef(false);
  const API = useApi();
  const singleUserValue = !isArray(value) ? value : undefined;

  // TODO: simplify (no longer calling in parallel)
  const fetchUsers = useMemo(
    () => throttle((request, callback) => {
      // Dont want to fail whole user search in the case that Twitter API search breaks.
      Promise.allSettled([
        API.searchUsers(request.query, request.postId),
        Promise.resolve(null),
      ]).then(([userSearch]) => {
        if (userSearch.status === 'rejected') {
          throw new Error('failed to fetch users from gondola database');
        }
        const userSearchVal: (User | SearchableUser | NetworkUser)[] = castArray(userSearch.value);
        const res = userSearchVal;
        return callback(res);
      }).catch((error) => {
        console.error(`Something went wrong fetching users: ${error}`);
        isFetching.current = false;
        setInputValue('');
      });
    }, 400),
    [],
  );

  const setDefaultUsers = async (pId: number) => {
    try {
      const usersForPost = await API.suggestUsersForPost(pId, creditType || 'child');
      if (usersForPost?.length) {
        setOptions(usersForPost);
      }
    } catch (err) {
      console.error(`Something went wrong suggesting users: ${err}`);
    }
  };

  useEffect(() => {
    if (postId && !inputValue) {
      setDefaultUsers(postId);
    }
  }, [postId, inputValue]);

  useEffect(() => {
    isFetching.current = true;
    let active = true;
    const sanitizedInput = (inputValue || '').replace(/[^a-zA-Z0-9\s._]/g, '');
    const formattedValue = castArray(value);

    if (isEmpty(sanitizedInput)) {
      if (multiple) {
        setOptions(formattedValue);
      } else {
        setOptions(formattedValue || []);
      }
      return undefined;
    }

    fetchUsers({ query: sanitizedInput, postId }, (results: SearchableUser[]) => {
      isFetching.current = false;
      if (active) {
        let newOptions: (string | User | SearchableUser)[] = [];
        if (value) {
          newOptions = formattedValue;
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchUsers]);

  return (
    <Autocomplete
      freeSolo={isCreatableSearch}
      getOptionLabel={(option: SearchableUser | User) => option?.name || ''}
      filterOptions={(theOptions: any, params: any) => {
        const filtered = theOptions.slice();
        // checks if any users are not in the database
        const showAddUser = filtered.every((userOpt: SearchableUser | NetworkUser) => {
          if (userOpt?.network) {
            return false;
          }
          if (userOpt && (
            userOpt?.username || '').toLowerCase() === (params?.inputValue || '').toLowerCase()) {
            return false;
          }
          return true;
        });
        // Suggest the creation of a new value
        if (isCreatableSearch && params.inputValue !== '' && !isFetching.current && showAddUser) {
          filtered.push({
            isCreatable: true,
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        if (inputValue.trim() === '' && emptyText) {
          filtered.push({
            inputValue: '',
            name: emptyText,
          });
        }

        return filtered;
      }}
      getOptionDisabled={(option) => option?.name === emptyText}
      multiple={multiple}
      // TODO: figure out better typing for options
      //@ts-ignore
      options={options}
      selectOnFocus
      clearOnBlur
      blurOnSelect={!multiple}
      handleHomeEndKeys
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={onChange}
      onInputChange={(_: SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => {
        const inputProps = typeof singleUserValue !== 'string' && !multiple && !isEmpty(value) ? {
          ...params.InputProps,
          startAdornment: <Avatar
            key={singleUserValue?.id}
            variant="circular"
            src={getAvatarOrPlaceholder((value as SearchableUser)?.avatarUrl || '', singleUserValue?.id)}
            sx={{ width: '24px', height: '24px', marginRight: '8px' }}
          />,
        } : { ...params.InputProps };

        return (
          <TextField
            {...params}
            autoFocus={showSearchIcon}
            InputProps={{
              ...inputProps,
              ...(showSearchIcon && {
                endAdornment: (
                  <div className="text-lightgray">
                    <SearchRounded />
                  </div>
                ),
              }),
            }}
            label={label || 'User'}
            fullWidth
            variant={variant || 'outlined'}
          />
        );
      }}
      renderOption={(renderProps, option) => {
        const name = option?.name;

        if (emptyText && emptyText === name) {
          return (
            <li {...renderProps} key={0}>
              {emptyText}
            </li>
          );
        }

        return (
          <li {...renderProps} key={`${option?.id || -1}-${name || 'name'}`}>
            <div className="flex items-center">
              <div className="mr-4">
                {option ? (
                  <Avatar
                    alt={name}
                    src={getAvatarOrPlaceholder(option.avatarUrl, option.id)}
                  />
                ) : null}
              </div>
              <div>
                <div className="font-semibold">
                  {name}
                </div>
                <div className="text-sm">
                  {option?.username}
                </div>
              </div>
            </div>
          </li>
        );
      }}
      size={size}
      noOptionsText={emptyText}
    />
  );
};
