import {
  forwardRef, Ref, DetailedHTMLProps, InputHTMLAttributes,
} from 'react';

interface Props
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  hasError?: boolean;
}

export const FormInput = forwardRef(({
  hasError, ...props
}: Props, ref: Ref<HTMLInputElement>) => {
  const border = hasError ? 'border-danger' : '';
  return (
    <input
      className={`bg-hintgray border border-lightgray text-lg p-4 w-full rounded-lg outline-none ${border}`}
      ref={ref}
      {...props}
    />
  );
});
